import { useCallback, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import WithLoader from 'components/Custom/WithLoader';
import SearchBar from 'components/Custom/SearchBar';
import axios from 'utilities/authAxios';
import DataGridTable, { createQuery } from 'components/Custom/DataGridTable';
import DataGridPagination from 'components/Custom/DataGridPagination';
import useTableState from 'hooks/useTableState';
import COLUMNS from './CorrespondenceTableColumns';
import CorrespondencesCard from './CorrespondencesCard';

const INITIAL_STATE = {
  pageNumber: 0,
  pageSize: 25,
  sort: { field: 'correspondenceLogId', order: 'desc' },
  filter: undefined,
};

function CorrespondencePage(props) {
  const [refresh, toggleRefresh] = useState(false);
  const { accountId } = useParams();
  const history = useHistory();
  const queryCorrespondences = useMemo(
    () =>
      createQuery({
        extraParams: { accountId },
        presentAlert: props.presentAlert,
        dismissAlert: props.dismissAlert,
        setLoading: props.setLoading,
        url: `${process.env.REACT_APP_API_URL}/Correspondence/GetCorrespondenceByAccount`,
      }),
    [accountId, refresh]
  );

  const {
    data,
    totalCount,
    loading,
    onSortChange,
    onFilterChange,
    onPageChange,
    onPageSizeChange,
    onRefresh,
    sort,
    pageNumber,
    pageSize,
  } = useTableState(INITIAL_STATE, queryCorrespondences);

  const clearSearch = useCallback(() => {
    onFilterChange(undefined);
  }, [onFilterChange]);

  const handleSearchSubmit = useCallback(
    (searchBy, searchString = '', startDate = '', endDate = '') => {
      onFilterChange({
        type: searchBy,
        keyword: searchString,
        startDate,
        endDate,
      });
    },
    []
  );

  const viewCorrespondence = (correspondenceLogId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Correspondence/GetCorrespondence/${correspondenceLogId}`
      )
      .then((res) => {
        history.push({
          pathname: `/account-management/${accountId}/correspondence-view`,
          state: {
            correspondenceData: res.data,
          },
        });
      })
      .catch((err) => props.presentAlert('error', err.response?.data));
  };

  const resendCorrespondence = (correspondenceLogId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Correspondence/${accountId}/ReSendNotification/${correspondenceLogId}`
      )
      .then((res) => {
        if (res.data) {
          props.presentAlert('success', 'Correspondence has been re-sent');
          toggleRefresh((prevRefresh) => !prevRefresh);
        } else {
          props.presentAlert('error', 'Failed to re-send correspondence');
        }
      })
      .catch(() => props.presentAlert('error', 'Failed to re-send correspondence'));
  };

  const components = useMemo(
    () => ({
      // eslint-disable-next-line react/no-unstable-nested-components
      Row: (rowProps) => (
        <CorrespondencesCard
          viewCorrespondence={viewCorrespondence}
          resendCorrespondence={resendCorrespondence}
          accountId={accountId}
          presentAlert={props.presentAlert}
          correspondence={rowProps.row}
          index={rowProps.index}
          renderedColumns={rowProps.renderedColumns}
          rowHeight={rowProps.rowHeight}
        />
      ),
      // eslint-disable-next-line
      Pagination: (props) => <DataGridPagination {...props} onRefresh={onRefresh} />,
    }),
    [accountId, props.presentAlert]
  );

  return (
    <div>
      {!props.loading && (
        <Stack direction="column" spacing={2}>
          <SearchBar
            parentId="Correspondence"
            handleSearchSubmit={handleSearchSubmit}
            clearSearch={clearSearch}
            searchTypes={['Notification Date', 'Event Code', 'Function']}
          />
          <DataGridTable
            columns={COLUMNS}
            loading={loading}
            data={data}
            overridingComponent={components}
            pageNumber={pageNumber}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onRefresh={onRefresh}
            totalCount={totalCount}
            sort={sort}
            onSortModelChange={onSortChange}
          />
        </Stack>
      )}
    </div>
  );
}

CorrespondencePage.propTypes = {
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired,
};

export default WithLoader(CorrespondencePage);
