import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Alert from 'components/Custom/Alert';
import CaseSearchPage from 'components/Account/CaseManagement/CaseSearchPage';
import AccountInfoPage from './AccountInfo/AccountInfoPage';
import ContactsPage from './Contacts/ContactsPage';
import NotesPage from './Notes/NotesPage';
import VehiclesPage from './Vehicles/VehiclesPage';
import AccountDevicesPage from './Devices/AccountDevicesPage';
import TransactionsPage from './Transactions/TransactionsPage';
import FinancialsPage from './Financials/FinancialsPage';
import StatementsPage from './Statements/StatementsPage';
import CorrespondencesPage from './Correspondences/CorrespondencesPage';
import PaymentMethodPage from './PaymentMethod/PaymentMethodPage';
import HistorySearchPage from './History/HistorySearchPage';

function tabSwitch(index, accountData, reloadParent) {
  switch (index) {
    case 0:
      return <AccountInfoPage accountData={accountData} reloadParent={reloadParent} />;
    case 1:
      return <ContactsPage />;
    case 2:
      return <PaymentMethodPage />;
    case 3:
      return <NotesPage />;
    case 4:
      return <VehiclesPage accountData={accountData} />;
    case 5:
      return <AccountDevicesPage />;
    case 6:
      return <TransactionsPage />;
    case 7:
      return <FinancialsPage />;
    case 8:
      return <StatementsPage />;
    case 9:
      return <CorrespondencesPage />;
    case 10:
      return <CaseSearchPage />;
    case 11:
      return <HistorySearchPage />;
    default:
      return <div />;
  }
}
function WrapPage(childrenId, index, accountData, reloadParent) {
  return <Alert childrenId={childrenId}>{tabSwitch(index, accountData, reloadParent)}</Alert>;
}
export default function TabPanel(props) {
  const { childrenId, value, index, accountData, reloadParent } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && (
        <Box sx={{ p: 3 }}>{WrapPage(childrenId, index, accountData, reloadParent)}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  childrenId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  accountData: PropTypes.shape({
    accountId: PropTypes.number,
    accountNumber: PropTypes.string,
    openDt: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
    plateNumber: PropTypes.string,
    vin: PropTypes.string,
    accountBalance: PropTypes.number,
    accountStatusId: PropTypes.number,
    accountStatusDt: PropTypes.string,
  }),
  reloadParent: PropTypes.func,
};

TabPanel.defaultProps = {
  accountData: null,
  reloadParent: undefined,
};
