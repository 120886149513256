import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

export default function CorrespondenceView(props) {
  const history = useHistory();
  return (
    <div style={{ padding: 8 }}>
      <button type="submit" onClick={() => history.goBack()}>
        Back
      </button>
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(props.location.state.correspondenceData),
        }}
      />
    </div>
  );
}

CorrespondenceView.propTypes = {
  location: PropTypes.string.isRequired,
};
