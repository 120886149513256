import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import axios from 'utilities/authAxios';
import { noteCallReasonAtom } from 'utilities/State';
import { useRecoilValue, useSetRecoilState } from 'recoil';

export default function AddNoteDialogForm(props) {
  const { accountId } = props;
  const [state, setState] = useState({
    accountId,
    noteDesc: '',
    reasonCodeId: 41,
    file: null,
  });
  const callReasons = useRecoilValue(noteCallReasonAtom);
  const [selectedCallReason, setSelectedCallReason] = useState(null);
  const setNoteCallReasonsAtom = useSetRecoilState(noteCallReasonAtom);

  const saveFile = (e) => {
    setState({
      ...state,
      file: e.target.files[0],
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', state.file);
    formData.append('accountId', accountId);
    formData.append('noteDesc', state.noteDesc);
    formData.append('reasonCodeId', state.reasonCodeId.toString());
    formData.append('noteTypeId', '1');
    formData.append('NoteCallReasonId', selectedCallReason);

    props.addNote(formData);
    props.handleClose();
  };

  const reasonCodes = [
    {
      reasonCodeDesc: 'Account Note',
      reasonCodeId: 41,
    },
    {
      reasonCodeDesc: 'Vehicle',
      reasonCodeId: 43,
    },
  ];

  const noteReasonMenuItems = reasonCodes.map((reason) => {
    const { reasonCodeDesc, reasonCodeId } = reason;
    return (
      <MenuItem
        key={reasonCodeDesc.replace(' ', '')}
        value={reasonCodeId}
        id={`AddNote-select-reasonCodeIdValue-${reasonCodeDesc.replace(' ', '')}`}
      >
        {reasonCodeDesc}
      </MenuItem>
    );
  });

  const getNoteCallReasons = () => {
    props.setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/Note/Call/Reasons`)
      .then((res) => {
        setNoteCallReasonsAtom(res.data);
        props.setLoading(false);
      })
      .catch(() => {
        props.setLoading(false);
        props.presentAlert('error', 'Failed to retrieve note callReasons');
      });
  };

  const handleChangedCallReason = (e) => {
    setSelectedCallReason(e.target.value);
  };

  useEffect(() => {
    if (callReasons.length === 0) {
      getNoteCallReasons();
    }
  }, [callReasons]);

  return (
    <div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                select
                fullWidth
                size="small"
                label="Reason"
                id="AddNote-select-reasonCodeId"
                value={state.reasonCodeId}
                name="reasonCodeId"
                onChange={handleChange}
              >
                {noteReasonMenuItems}
              </TextField>
            </Grid>
            <Box width="100%" />
            <Grid item xs={12}>
              <Box>
                <TextField
                  select
                  fullWidth
                  size="small"
                  label="Call Reason"
                  id="AddNote-select-callReasonCode"
                  value={selectedCallReason}
                  name="reason"
                  onChange={handleChangedCallReason}
                >
                  {Object.entries(callReasons).map((option, index) => (
                    <MenuItem id={`select-category-${index}`} value={option[1].noteCallReasonId}>
                      {option[1].callReasonCode}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                multiline
                label="Note Description"
                variant="outlined"
                minRows={10}
                placeholder="Enter note description..."
                id="AddNote-textField-noteDesc"
                name="noteDesc"
                value={state.noteDesc}
                onChange={handleChange}
                inputProps={{ maxLength: 2000 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Characters Remaining: {2000 - state.noteDesc.length}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormLabel htmlFor="contained-button-file">
                <Input
                  id="AddNote-input-uploadFile"
                  type="file"
                  name="uploadFile"
                  onChange={saveFile}
                />
              </FormLabel>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            id="AddNote-btn-cancel"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            id="AddNote-btn-createNote"
            type="submit"
            disabled={selectedCallReason == null}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </div>
  );
}

AddNoteDialogForm.propTypes = {
  addNote: PropTypes.func.isRequired,
  accountId: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  presentAlert: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};
