import DataGridHeader from 'components/Custom/DataGridHeader';

const COLUMNS = [
  {
    field: 'created',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="created" heading="Date Created" />,
  },
  {
    field: 'callReasonCode',
    flex: 1,
    renderHeader: () => <DataGridHeader columnId="callReasonCode" heading="Call Reason" />,
    sortable: false,
  },
  {
    field: 'noteDesc',
    flex: 3,
    renderHeader: () => <DataGridHeader columnId="noteDesc" heading="Comment" />,
    sortable: false,
  },
  {
    field: 'noteType',
    flex: 0.5,
    renderHeader: () => <DataGridHeader columnId="noteType" heading="Note Type" />,
    sortable: false,
  },
  {
    field: 'csrUserName',
    flex: 0.5,
    renderHeader: () => <DataGridHeader columnId="csrUserName" heading="User Name" />,
    sortable: false,
  },
  {
    field: 'attachment',
    flex: 0.5,
    renderHeader: () => <DataGridHeader columnId="attachment" heading="Attachment" />,
    sortable: false,
  },
];

export default COLUMNS;
