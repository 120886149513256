import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import axios from 'utilities/authAxios';
import { accountSearchRight, redirectTo } from 'utilities/userRole';
import { useParams, withRouter } from 'react-router-dom';
import WithLoader from 'components/Custom/WithLoader';
import Alert from 'components/Custom/Alert';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import {
  currentActiveAlert,
  refreshAccountInfo,
  accountRebillMethodAtom,
  vehicleDetailsAtom,
  globalAccountStatusId,
} from 'utilities/State';
import AccountInfoHeader from './AccountInfoHeader';
import TabPanel from './TabPanel';

function AccountMgmtPage(props) {
  const hasSearchAccountRight = useRecoilValue(accountSearchRight);
  const [value, setValue] = useState(0);
  const [accountData, setAccountData] = useState();
  const refresh = useRecoilValue(refreshAccountInfo);
  const [activeAlert] = useRecoilState(currentActiveAlert);
  const { accountId } = useParams();
  const setAccountRebillMethod = useSetRecoilState(accountRebillMethodAtom);
  const setVehicleDetails = useSetRecoilState(vehicleDetailsAtom);
  const setAccountStatusIdGlobal = useSetRecoilState(globalAccountStatusId);

  const handleChange = (e, newValue) => {
    if (activeAlert.dismiss) activeAlert.dismiss();
    setValue(newValue);
  };

  const isAccountInfoTab = value === 0;

  const getAccountInfo = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Account/GetAccountInfo/${accountId}`)
      .then((res) => {
        setAccountData(res.data);
        setAccountRebillMethod(res.data.rebillMethodId);
        setAccountStatusIdGlobal(res.data.accountStatusId);
        props.setLoading(false);
      })
      .catch((err) => {
        if (err.response?.status === 404 || err.response?.status === 400)
          props.presentError('Account could not be found', '/');
        else props.presentError('Server could not be reached. Please refresh the page.');
      });
  };

  const fetchVehicleDetailsGlobally = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Vehicle/GetVehicleDetails/${accountId}`)
      .then((res) => {
        setVehicleDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAccountInfo();
    fetchVehicleDetailsGlobally();
  }, [isAccountInfoTab, refresh, hasSearchAccountRight, accountId]);
  if (!hasSearchAccountRight) {
    redirectTo('/');
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Alert childrenId="AccountInfoHeader">
        <AccountInfoHeader data={accountData} />
      </Alert>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Account Information" id="AccountManagement-tab-accountInfo" />
          <Tab label="Contacts" id="AccountManagement-tab-contacts" />
          <Tab label="Payment Method" id="AccountManagement-tab-paymentMethod" />
          <Tab label="Notes" id="AccountManagement-tab-notes" />
          <Tab label="Vehicles" id="AccountManagement-tab-vehicles" />
          <Tab label="Devices" id="AccountManagement-tab-devices" />
          <Tab label="Transactions" id="AccountManagement-tab-transactions" />
          <Tab label="Financials" id="AccountManagement-tab-financials" />
          <Tab label="Statements" id="AccountManagement-tab-statements" />
          <Tab label="Correspondences" id="AccountManagement-tab-correspondences" />
          <Tab label="Cases" id="AccountManagement-tab-cases" />
          <Tab label="History" id="AccountManagement-tab-history" />
        </Tabs>
      </Box>

      <TabPanel
        childrenId="AccountInformation"
        value={value}
        accountData={accountData}
        index={0}
        reloadParent={getAccountInfo}
      />
      <TabPanel childrenId="Contacts" value={value} index={1} />
      <TabPanel childrenId="PaymentMethod" value={value} index={2} />
      <TabPanel childrenId="Notes" value={value} index={3} />
      <TabPanel childrenId="Vehicles" value={value} index={4} accountData={accountData} />
      <TabPanel childrenId="AccountDevices" value={value} index={5} />
      <TabPanel childrenId="Transactions" value={value} index={6} />
      <TabPanel childrenId="Financials" value={value} index={7} />
      <TabPanel childrenId="Statements" value={value} index={8} />
      <TabPanel childrenId="Correspondences" value={value} index={9} />
      <TabPanel childrenId="Cases" value={value} index={10} />
      <TabPanel childrenId="History" value={value} index={11} />
    </Box>
  );
}

AccountMgmtPage.propTypes = {
  setLoading: PropTypes.func.isRequired,
  presentError: PropTypes.func.isRequired,
};

export default withRouter(WithLoader(AccountMgmtPage));
